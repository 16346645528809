import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ImageGallery from "../../components/ImageGallery";
import GridNav from "../../components/GridNav";

export default function Index({
  data: {
    contentfulLandingPage,
    contentfulNavigationBar,
    contentfulFooter,
  },
}) {
  const data = contentfulLandingPage;
  return (
    <Layout
      footerElements={contentfulFooter}
    >
      <Header
        navBar={contentfulNavigationBar.navigationBarList}
        heading={data.heading}
        subheading={data.subheading}
        buttonList={data.buttonList}
        vanish={true}
        image={data.headerImage}
      />
      <ImageGallery images={data.imagegallery} />
      <GridNav navListHeading={data.navListHeading} navList={data.navList} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulLandingPage(node_locale: { eq: "de" }) {
      ...HeaderWithButtons
      ...ImageGallery
      ...GridNav
    }
    contentfulNavigationBar(node_locale: { eq: "de" }) {
      navigationBarList {
        linksToSlug
        title
      }
    }
    contentfulFooter(node_locale: { eq: "de" }) {
      footerElements {
        linksToSlug
        title
      }
    }
  }
`;
